export const modulesConfigReceitaFederal =
    {
        name: 'receita-federal',
        routes: {
            'app/main/apps/pesquisa': [
                {
                    path: 'pessoa-fisica',
                    loadChildren: () => import(
                        'modules/receita-federal/app/main/apps/pesquisa/pessoa-fisica/pessoa-fisica.module'
                        ).then(m => m.PessoaFisicaModule)
                },
                {
                    path: 'pessoa-juridica',
                    loadChildren: () => import(
                        'modules/receita-federal/app/main/apps/pesquisa/pessoa-juridica/pessoa-juridica.module'
                        ).then(m => m.PessoaJuridicaModule)
                },
                {
                    path: 'dados-basicos',
                    loadChildren: () => import(
                        'modules/receita-federal/app/main/apps/pesquisa/dados-basicos/dados-basicos.module'
                        ).then(m => m.DadosBasicosModule)
                },
                {
                    path: 'relatorio',
                    loadChildren: () => import(
                        'modules/receita-federal/app/main/apps/pesquisa/relatorio-view/relatorio-view.module'
                        ).then(m => m.RelatorioViewModule)
                }
            ],

        },
        components: {
          //  'app/main/apps/processo#converter': [
         //       () => import('modules/consultivo/app/main/apps/processo/processo-consultivo.module').then(m => m.ProcessoConsultivoModule)
         //   ]
        },
        sidebars: {
            'app/main/apps/pesquisa/sidebars/main': [
                {
                    nome: 'Pessoa Física',
                    icon: 'people',
                    link: 'pessoa-fisica',
                    role: [ 'ROLE_RF_COLABORADOR'],
                },
                {
                    nome: 'Pessoa Jurídica',
                    icon: 'people',
                    link: 'pessoa-juridica',
                    role: ['ROLE_RF_COLABORADOR'],
                },
   /*             {
                    nome: 'Socio',
                    icon: 'people',
                    link: 'socio',
                    role: 'ROLE_USER',
                },*/
            ]
        },
        mainMenu: [
            /*{
                id: 'modulos',
                entries: [{
                    id: 'receita-federal',
                    title: 'Receita Federal',
                    type: 'item',
                    icon: 'start',
                    url: '/apps/pesquisa/pessoa-fisica'
                }]
            }*/
        ],
        routerLinks: {
           /* 'app/main/apps/tarefas/tarefa-detail': {
                atividades: {
                    consultivo: 'atividades-consultivo/criar'
                }
            },*/
        },
        ajuda: [],
        widgets: [],
        generoAffinity: {},
        navigationConverter: {
            mainMenu: {}
        }
    };
