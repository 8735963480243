<form name="form" [formGroup]="form">

    <div class="cdk-barra-pesquisa">
        <div class="sm:hidden flex items-center">
            <button (click)="routeSearch()" mat-icon-button  class="custom-search-button"
                    aria-label="Pesquisar por">
                <mat-icon svgIcon="mat_outline:manage_search"></mat-icon>
            </button>
        </div>

        <div class="barra-pesquisa-final">

            <ng-container *ngIf="isUserConsultivo">
                <ng-template #buscaComponent></ng-template>

            </ng-container>

            <div class="cdk-barra-pesquisa-content xs:hidden">

                <div class="form-field-flex">
                    <button mat-icon-button
                            class="custom-search-button"
                            aria-label="Pesquisar por"
                            [matTooltip]="'Pesquisando processos por ' + this.searchFieldName"
                            [matMenuTriggerFor]="menuBusca">
                        <mat-icon svgIcon="mat_outline:manage_search"></mat-icon>
                    </button>
                    <!-- /* ALTERADO PELA PGE-RS: remoção de 'rápida de processos ' */ -->
                    <input class="input-field flex grow"
                        placeholder="Pesquisa por {{searchFieldName}}"
                        formControlName="processo"
                        [matAutocomplete]="processoSearch.autocomplete"
                        (blur)="checkProcesso()">
                    <cdk-processo-search-autocomplete
                        #processoSearch="processoSearchAutocomplete"
                        [searchField]="searchField"
                        [openBlank]="true"
                        [control]="form.get('processo')"
                        [pagination]="processoPagination">
                    </cdk-processo-search-autocomplete>
                </div>
            </div>
        </div>

    </div>
    <mat-menu #menuBusca="matMenu" class="menu-busca" [overlapTrigger]="false" xPosition="before">
        <!-- /* ALTERADO PELA PGE/RS: ajustes visuais. */ -->
        <div class="m-1">
            <div class="custom-search-header" style="border-bottom: 1px solid gray;">
                <span style="padding-bottom: 6px;">Buscar processos por:</span>
            </div>
            <br />
            <div class="campo" (click)="selecionaCampo('NUP', 'NUP', 'administrativo')" [ngClass]="{'selected': searchField === 'NUP'}">
                <span>NUP</span>
                <mat-icon class="text-base text-primary w-[14px] h-[14px] min-w-[14px] min-h-[14px]" *ngIf="searchField === 'NUP'">check</mat-icon>
            </div>
            <div class="campo" (click)="selecionaCampo('outroNumero', 'Outro Número', 'administrativo')" [ngClass]="{'selected': searchField === 'outroNumero'}">
                <span>Outro Número</span>
                <mat-icon class="text-base text-primary w-[14px] h-[14px] min-w-[14px] min-h-[14px]" *ngIf="searchField === 'outroNumero'">check</mat-icon>
            </div>

            <ng-template #dynamicComponent></ng-template>
        </div>
    </mat-menu>
</form>
