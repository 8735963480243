<ng-container *ngIf="!item.hidden && (isGrantedRole && (!item.isCoordenador || (item.isCoordenador && isCoordenador)))">
    <!-- normal collapsable -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="!item.url && !item.function"
       (click)="toggleOpen($event)">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.url -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && !item.function"
       (click)="toggleOpen($event)"
       [routerLink]="[item.url]" [routerLinkActive]="['active', 'bg-primary', 'text-on-primary']"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
       [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && !item.function"
       (click)="toggleOpen($event)"
       [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span class="nav-link" [ngClass]="item.classes" *ngIf="!item.url && item.function"
          (click)="toggleOpen($event);item.function()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && item.function"
       (click)="toggleOpen($event);item.function()"
       [routerLink]="[item.url]" [routerLinkActive]="['active', 'bg-primary', 'text-on-primary']"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && item.function"
       (click)="toggleOpen($event);item.function()"
       [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
        <span class="nav-link-title" [translate]="item.translate">{{item.title}}</span>
        <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
              [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
            {{item.badge.title}}
        </span>
        <span class="nav-link-badge-shares" *ngIf="item.badgeCompartilhada" matTooltip="Compartilhadas"
              [ngStyle]="{'background-color': item.badgeCompartilhada.badge.bg,'color': item.badgeCompartilhada.badge.fg}">
            {{item.badgeCompartilhada.badge.title}}
        </span>
        <mat-icon class="collapsable-arrow">keyboard_arrow_right</mat-icon>
    </ng-template>

    <div class="children" [@slideInOut]="isOpen">
        <!-- ALTERADO PELA PGE/RS: não esconder os itens. -->
        <ng-container *ngFor="let child of item.children">
            <cdk-nav-vertical-item *ngIf="child.type==='item'" [item]="child" [hidden]="child.id==='outros-generos'"></cdk-nav-vertical-item>
            <cdk-nav-vertical-collapsable *ngIf="child.type==='collapsable' && item.allowGroupOfHiddenChildren"
                                          [item]="child"
                                          [hidden]="child.id==='outros-generos'"
                                          [allowGroupOfHiddenChildren]="false"></cdk-nav-vertical-collapsable>
            <cdk-nav-vertical-group *ngIf="child.type==='group'" [item]="child" [hidden]="child.id==='outros-generos'"></cdk-nav-vertical-group>
        </ng-container>
    </div>

</ng-container>
