import {CdkNavigationItem} from '../../@cdk/types';

export const modulesConfigConsultivo =
    {
        name: 'consultivo',
        generoProcesso: ['CONSULTIVO'],
        routes: {
            'app/main/apps/tarefas/tarefa-detail': [
                {
                    path: 'atividades-consultivo',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/consultivo/app/main/apps/tarefas/tarefa-detail/atividades/atividades-consultivo.module'
                        ).then(m => m.AtividadesConsultivoModule)
                }
            ],
            'app/main/apps/tarefas': [
                {
                    path: 'atividade-bloco-consultivo',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/consultivo/app/main/apps/tarefas/atividade-create-bloco/atividade-create-bloco-consultivo.module'
                        ).then(m => m.AtividadeCreateBlocoConsultivoModule)
                }
            ],
            'app/main/apps/documento/documento-edit': [
                {
                    path: 'atividade-consultivo',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/consultivo/app/main/apps/documento/documento-edit/atividade/atividade-consultivo.module'
                        ).then(m => m.AtividadeConsultivoModule)
                }
            ],
            'app/main/apps/admin': [
                {
                    path: 'complemento-consultivo',
                    loadChildren: () => import(
                        'modules/consultivo/app/main/apps/admin/complemento-consultivo/complemento-consultivo.module'
                        ).then(m => m.ComplementoConsultivoModule)
                },
            ],
            'app/main/apps/processo/processo-edit/tarefas': [
                {
                    path: 'atividades-consultivo',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/consultivo/app/main/apps/processo/processo-edit/tarefas/atividades/atividade-list/atividade-consultivo-list.module'
                        ).then(m => m.AtividadeConsultivoListModule)
                }
            ],
            'app/main/apps/pesquisa': [
                {
                    path: 'consultivo',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/consultivo/app/main/apps/pesquisa/pesquisa-consultivo/pesquisa-consultivo.module'
                        ).then(m => m.PesquisaConsultivoModule)
                }
            ],
        },
        components: {
            'app/main/apps/processo#converter': [
                () => import('modules/consultivo/app/main/apps/processo/processo-consultivo.module').then(m => m.ProcessoConsultivoModule)
            ],
            '@cdk/components/search-bar-busca': [
                () => import('modules/consultivo/@cdk/components/barra-pesquisa/barra-pesquisa.module').then(m => m.BarraPesquisaModule)
            ],
        },
        sidebars: {
            'app/main/apps/admin/sidebars/main': [
                {
                    nome: 'Complemento Consultivo',
                    icon: 'toll',
                    link: 'complemento-consultivo',
                    role: 'ROLE_ADMIN'
                },
            ],
            // 'app/main/apps/pesquisa/sidebars/main': [
            //     {
            //         nome: 'Documentos',
            //         icon: 'search',
            //         link: 'consultivo',
            //         role: 'ROLE_USER'
            //     },
            // ],
        },
        mainMenu: [
            {
                id: 'protocolo',
                entries: [{
                    id: 'consultivo',
                    title: 'Consultivo',
                    type: 'item',
                    icon: 'auto_stories', // ALTERADO PELA PGE-RS: ícone.
                    url: '/apps/processo/criar/editar/dados-basicos-steps/consultivo'
                }]
            },
            {
                id: 'tarefas',
                entries: [{
                    id: 'tarefas_pendentes_consultivo',
                    title: 'Consultivo',
                    type: 'item',
                    icon: 'auto_stories', // ALTERADO PELA PGE-RS: ícone.
                    hideable: true,
                    url: '/apps/tarefas/consultivo/minhas-tarefas/entrada',
                    badge: {
                        title: '0',
                        bg : '#F44336',
                        fg: '#FFFFFF'
                    },
                    badgeCompartilhada: {
                        id: 'tarefas_compartilhadas_consultivo',
                        parent: false,
                        badge: {
                            title: '0',
                            bg: '#fc6054',
                            fg: '#FFFFFF'
                        }
                    },
                    canHide: (item: CdkNavigationItem): boolean => {
                        let countBadges = 0;
                        countBadges = parseInt(item.badge?.title, 10);
                        if (item.badgeCompartilhada) {
                            countBadges += parseInt(item.badgeCompartilhada.badge?.title, 10);
                        }
                        return countBadges === 0;
                    }
                }]
            }
        ],
        routerLinks: {
            'app/main/apps/tarefas/tarefa-detail': {
                atividades: {
                    consultivo: 'atividades-consultivo/criar'
                }
            },
            'app/main/apps/tarefas': {
                'atividades': {
                    consultivo: 'atividades-consultivo/criar'
                },
                'atividade-bloco': {
                    consultivo: 'atividade-bloco-consultivo'
                }
            },
            'app/main/apps/documento/documento-edit': {
                atividade: {
                    consultivo: 'atividade-consultivo'
                }
            },
            'app/main/apps/processo/processo-edit/tarefas/tarefa-list': {
                atividades: {
                    consultivo: 'atividades-consultivo/'
                }
            },
            'app/main/apps': {
                'pesquisa': {
                    consultivo: 'consultivo'
                }
            }
        },
        ajuda: [],
        widgets: [],
        generoAffinity: {},
        navigationConverter: {
            'mainMenu': {}
        }
    };
